import{
    P
} from './styles';

import {
    Container,
    ContainerCollumContent,
    ContainerRow,
    BoxForm,
    BoxContent,
    BoxFooter,
    Title,
    SelectsRow,
    Select,
    Option,
    FooterItem,
    SelectContent,
    SelectsDiv
} from '../../styles/shared';

import { Button } from '../../styles/shared';

import{
    FileArrowDown,
    PaperPlaneRight,
    FilePlus,
    CheckSquare,
    Trash,
    Files,
    SignOut,
    IdentificationCard,
    FileArchive,
    BoxArrowDown,
    Invoice
} from "@phosphor-icons/react";

import { Toaster, toast } from 'sonner'

import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import { useEffect, useState } from 'react';
import { modeloService } from './services/modeloService';
import { useAuth } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';
import InputNotation from '../../components/InputNotation';

import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
  
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableHeader from '@tiptap/extension-table-header'
import TableCell from '@tiptap/extension-table-cell'
import TextAlign from '@tiptap/extension-text-align'
import FixedImage from '../../components/InputNotation/FixedImage'
import { useEditor } from '@tiptap/react';
import { DialogContent, DialogDescription, DialogOverlay, DialogPortal, DialogRoot, DialogTitle } from '../../components/Dialog';
import * as Tabs from '@radix-ui/react-tabs';

export const extensions = [
    StarterKit.configure({
      considerAnyAsEmpty: true,
    }),
    Underline,
    TextStyle,
    Color,
    Highlight.configure({ multicolor: true }),
    Table,
    TableRow,
    TableHeader,
    TableCell,
    // Image.configure({
    //   allowBase64: true,
    // })
    FixedImage.configure({
      allowBase64: true,
    }),
    // Heading,
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
  ]

const Home = () => {
    // const [titulo, setTitulo] = useState('');
    // const [corpo, setCorpo] = useState('');
    // const [assinante, setAssinante] = useState('');
    // const [localData, setLocalData] = useState('');
    const [assunto, setAssunto] = useState('');
    const [corpoEmail, setCorpoEmail] = useState('');
    const [arquivo, setArquivo] = useState(null);
    const [setor, setSetor] = useState('0');
    const [modelos, setModelos] = useState([]);
    const [modelIdSelected, setModelIdSelected] = useState(null);
    const [selectValue, setSelectValue] = useState('0');
    const [emailCopia, setEmailCopia] = useState('0');
    const [empresa, setEmpresa] = useState('1');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [tabSelected, setTabSelected] = useState('tab-adicionar');
    const [nomeModeloToSend, setNomeModeloToSend] = useState('');

    const defaultEditor = useEditor({
        editable: true,
        extensions,
        // content: '<h2 style="text-align: center">Recibo de Vale Refeição</h2><p>Lorem ipsum dolor sit amet {nome} adipisicing elit. Saepe quo, aliquam consectetur accusamus nulla repellat quas? Incidunt commodi ad mollitia, recusandae sapiente, voluptate molestiae, impedit officia non enim explicabo. Assumenda.</p><p><strong>teste</strong></p><p><u>teste</u></p><p><em>teste</em></p><p><span style="color: rgb(255, 0, 0)">teste</span></p><p><span style="color: rgb(0, 0, 0)"><mark data-color="#ffe066" style="background-color: #ffe066; color: inherit">teste</mark></span></p><ol><li><p>a</p></li><li><p>b</p></li><li><p>c</p></li></ol><ul><li><p>a</p></li><li><p>b</p></li><li><p>c</p></li></ul><table style="minWidth: 75px"><colgroup><col><col><col></colgroup><tbody><tr><th colspan="1" rowspan="1"><p>teste</p></th><th colspan="1" rowspan="1"><p>teste</p></th><th colspan="1" rowspan="1"><p>teste</p></th></tr><tr><td colspan="1" rowspan="1"><p>a</p></td><td colspan="1" rowspan="1"><p>a</p></td><td colspan="1" rowspan="1"><p>s</p></td></tr><tr><td colspan="1" rowspan="1"><p>z</p></td><td colspan="1" rowspan="1"><p>a</p></td><td colspan="1" rowspan="1"><p>a</p></td></tr></tbody></table><br><p>Fortaleza, 12/04/2023</p><br><p style="text-align: center">______________________</p><p style="text-align: center">{nome}</p><p>1</p><p>2</p><p>3</p>',
      })

    const { logout } = useAuth();
    const navigate = useNavigate();

    const emails = [
        "rh@center1hotel.com.br",
        "rh@eldoradohotel.tur.br",
        "rh@okintercambio.com.br" 
    ]


    const loadModelos = async () => {
        const respModelos = await modeloService.getModelos();
        // console.log(respModelos);
        setModelos(respModelos);
    }

    useEffect(() => {
        loadModelos()
    }, []);

    const gerarHTMLFinal = () => {
        const replaced = defaultEditor.getHTML().replace(/{/g, '´´').replace(/}/g, '``').replace(/<p><\/p>/g, '<br>');
        //replace '<p></p>' for <br> on html
        // const replaced = corpoHTML.replace(/<p><\/p>/g, '<br>');
        const html = `
            <html>
                <style>
                    * {
                        margin: 0;
                        padding: 0;
                        box-sizing: border-box;
                    }
                    p:empty::before {
                        content: "\\00a0"; /* Non-breaking space */
                        display: inline-block;
                    }
                </style>
                <style>.tiptap{outline:0;border:none;padding: 0.5rem;color:#1c1b1f;font-size:20px;font-style:normal;font-weight:400;line-height:normal}.tiptap>*+*{margin-top:.75em}.tiptap strong{font-weight:700}.tiptap ol,.tiptap ul{padding:0 1rem}.tiptap h1,.tiptap h2,.tiptap h3,.tiptap h4,.tiptap h5,.tiptap h6{line-height:1.1;font-weight:700;text-wrap:pretty}.tiptap h1 *,.tiptap h2 *,.tiptap h3 *,.tiptap h4 *,.tiptap h5 *,.tiptap h6 *{font-size:inherit}.tiptap h1,.tiptap h2{/*margin-top:3.5rem;*/margin-bottom:1.5rem}.tiptap h1{font-size:1.4rem}.tiptap h2{font-size:1.2rem}.tiptap h3{font-size:1.1rem}.tiptap h4,.tiptap h5,.tiptap h6{font-size:1rem}.tiptap code{background-color:rgba(97,97,97,.1);color:#616161}.tiptap pre{background:#0d0d0d;color:#fff;font-family:JetBrainsMono,monospace;padding:.75rem 1rem;border-radius:.5rem}.tiptap pre code{color:inherit;padding:0;background:0 0;font-size:.8rem}.tiptap img{max-width:100%;height:auto}.tiptap blockquote{padding-left:1rem;border-left:2px solid rgba(13,13,13,.1)}.tiptap hr{border:none;border-top:2px solid rgba(13,13,13,.1);margin:2rem 0}.tiptap table{border-collapse:collapse;margin:0;overflow:hidden;table-layout:fixed;width:100%}.tiptap table td,.tiptap table th{border:2px solid #ced4da;box-sizing:border-box;min-width:1em;padding:3px 5px;position:relative;vertical-align:top}.tiptap table td>*,.tiptap table th>*{margin-bottom:0}.tiptap table th{background-color:#f1f3f5;font-weight:700;text-align:left}.tiptap table .selectedCell:after{background:rgba(200,200,255,.4);content:'';left:0;right:0;top:0;bottom:0;pointer-events:none;position:absolute;z-index:2}.tiptap table .column-resize-handle{background-color:#adf;bottom:-2px;position:absolute;right:-2px;pointer-events:none;top:0;width:4px}.tiptap table p{margin:0}</style>
                <body>
                    <div class="tiptap">
                        ${replaced}
                    </div>
                </body>
            </html>
        `
        return html;
    }

    const handleLoadModeloSelected = async (id) => {
        if (id !== '0') {
            setModelIdSelected(id);
            setSelectValue(id);
            const respModelo = await modeloService.getModeloById(id);
            setNomeModeloToSend(respModelo.titulo_documento);
            // setCorpo(respModelo.corpo_documento);
            // setCorpoHTML(respModelo.corpo_documento);
            // console.log(respModelo.corpo_documento);
            defaultEditor.commands.setContent(respModelo.corpo_documento);
            // setAssinante(respModelo.assinante_documento);
            // setLocalData(respModelo.local_data_documento);
            setAssunto(respModelo.assunto_email_documento);
            setCorpoEmail(respModelo.corpo_email_documento);
            setSetor(respModelo.setor_documento);
            setEmpresa(respModelo.empresa_documento);
            if (respModelo.copia_documento && respModelo.copia_documento !== '') {
                setEmailCopia(respModelo.copia_documento);
            }else{
                setEmailCopia('0');
            }
        }else{
            setModelIdSelected(null);
            setSelectValue('0');
            setNomeModeloToSend('');
            // setCorpoHTML('');
            defaultEditor.commands.setContent('');
            // setAssinante('');
            // setLocalData('');
            setAssunto('');
            setCorpoEmail('');
            setSetor('0');
            setEmpresa('0');
            setEmailCopia('0');
        }
    }

    const handleSubmit = async () => {
        if (!fullValidate()) {
            return
        }
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        formData.append('tipo', setor);
        const html = gerarHTMLFinal();

        // formData.append('titulo', titulo);
        formData.append('documento', html);
        // formData.append('assinante', assinante);
        // formData.append('localData', localData);
        const replacedAssunto = assunto.replace(/{/g, '´´').replace(/}/g, '``');
        const replacedCorpoEmail = corpoEmail.replace(/{/g, '´´').replace(/}/g, '``');
        formData.append('assunto', replacedAssunto);
        formData.append('corpoEmail', replacedCorpoEmail);
        formData.append('empresa', empresa);
        formData.append('copia', emailCopia !== '0' ? emailCopia : null);

        // console.log(values);

        try {
            await modeloService.sendEmails(formData);
            toast.success('Os emails estão sendo enviados!');
            setNomeModeloToSend('');
            // setCorpo('');
            // setAssinante('');
            // setLocalData('');
            defaultEditor.commands.setContent('');
            setAssunto('');
            setCorpoEmail('');
            setSetor('0');
            setSelectValue('0');
            setEmpresa('0');
            setEmailCopia('0');
        } catch (error) {
            toast.error('Erro ao enviar os emails!');
        }
        
    };

    const handleAddModel = async () => {
        if (!editToSendValidate()) {
            return
        }
        const documento = defaultEditor.getHTML()

        const values = {
            // corpoDocumento: corpo,
            // assinanteDocumento: assinante,
            // localDataDocumento: localData,
            corpoDocumento: documento,
            tituloDocumento: nomeModeloToSend,
            assuntoEmail: assunto,
            corpoEmail: corpoEmail,
            setorDocumento: setor,
            empresaDocumento: empresa,
            copiaDocumento: emailCopia !== '0' ? emailCopia : null
        }
        // console.log(values);
        try {
            const responseModelo = await modeloService.createModelo(values);
            // console.log(responseModelo);
            setSelectValue(responseModelo.id_documento);
            setModelIdSelected(responseModelo.id_documento);
            toast.success('Modelo adicionado com sucesso!');
            closeDialog();
            loadModelos();
        } catch (error) {
            console.log(error);
            toast.error('Erro ao adicionar o modelo!');
        }
    }

    const openDialog = () => {
        setDialogOpen(true);
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    const handleTabAdicionar = () => {
        openDialog();
        setTabSelected('tab-adicionar');
    }

    const handleTabEditar = () => {
        openDialog();
        setTabSelected('tab-adicionar');
    }

    const handleTabDeletar = () => {
        openDialog();
        setTabSelected('tab-deletar');
    }

    const handleEditModel = async () => {
        if (!editToSendValidate()) {
            return
        }
        const documento = defaultEditor.getHTML()

        const values = {
            // corpoDocumento: corpo,
            // assinanteDocumento: assinante,
            // localDataDocumento: localData,
            corpoDocumento: documento,
            tituloDocumento: nomeModeloToSend,
            assuntoEmail: assunto,
            corpoEmail: corpoEmail,
            setorDocumento: setor,
            empresaDocumento: empresa,
            copiaDocumento: emailCopia !== '0' ? emailCopia : null
        }

        try{
            await modeloService.updateModelo(values, modelIdSelected);
            toast.success('Modelo atualizado com sucesso!');
            closeDialog()
            loadModelos()
        }catch(error){
            console.log(error);
            toast.error('Erro ao atualizar o modelo!');
        }
    }

    const handleDeleteModel = async () => {
        try {
            await modeloService.deleteModelo(modelIdSelected);
            toast.success('Modelo deletado com sucesso!');
            setNomeModeloToSend('');
            // setCorpo('');
            // setAssinante('');
            // setLocalData('');
            // setCorpoHTML('');
            defaultEditor.commands.setContent('');
            setAssunto('');
            setCorpoEmail('');
            setSetor('0');
            setSelectValue('0');
            setEmpresa('0');
            setEmailCopia('0');
            setModelIdSelected(null);
            closeDialog();
            loadModelos();
        } catch (error) {
            console.log(error);
            toast.error('Erro ao deletar o modelo!');
        }
    }

    const fullValidate = () => {
        //checa se os campos estão preenchidos
        if (defaultEditor.isEmpty || assunto === '' || corpoEmail === '' || arquivo === null || setor === '0' || empresa === '0') {
            toast.error('Preencha todos os campos!');
            return false;
        } else {
            return true;
        }
    };

    const downloadValidate = () => {
        //checa se os campos estão preenchidos, menos o setor, corpoEmail e assunto
        if (defaultEditor.isEmpty || arquivo === null || empresa === '0') {
            toast.error('Preencha todos os campos!');
            return false;
        } else {
            return true;
        }
    };

    // const editValidate = () => {
    //     //checa se os campos estão preenchidos, menos o setor e arquivo
    //     if (defaultEditor.isEmpty || assunto === '' || corpoEmail === '' || empresa === '0' || setor === '0') {
    //         toast.error('Preencha todos os campos!');
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    const editToSendValidate = () => {
        //checa se os campos estão preenchidos, menos o setor e arquivo
        if (defaultEditor.isEmpty || assunto === '' || corpoEmail === '' || empresa === '0' || setor === '0' || nomeModeloToSend === '') {
            toast.error('Preencha todos os campos!');
            return false;
        } else {
            return true;
        }
    }


    const handleDownload =  async () => {
        if (!downloadValidate()) {
            return
        }
        const formData = new FormData();
        const html = gerarHTMLFinal();
        formData.append('arquivo', arquivo);
        // formData.append('titulo', titulo);
        formData.append('documento', html);
        // formData.append('assinante', assinante);
        // formData.append('localData', localData);
        formData.append('empresa', empresa);
        try {
            const res = await modeloService.downloadExample(formData);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exemplo.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.error('Erro ao baixar o arquivo!');
            console.log(error);
        }

    };

    const handleDownloadAll =  async () => {
        if (!downloadValidate()) {
            return
        }
        const formData = new FormData();
        const html = gerarHTMLFinal();

        formData.append('arquivo', arquivo);
        // formData.append('titulo', titulo);
        formData.append('documento', html);
        // formData.append('assinante', assinante);
        // formData.append('localData', localData);
        formData.append('empresa', empresa);

        try {
            const res = await modeloService.downloadAll(formData);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exemplo_todos.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.error('Erro ao baixar o arquivo!');
            console.log(error);
        }
    }

    const handleDownloadRecibos =  async () => {
        if (!downloadValidate()) {
            return
        }
        const formData = new FormData();
        const replaced = defaultEditor.getHTML().replace(/{/g, '´´').replace(/}/g, '``').replace(/<p><\/p>/g, '<br>');

        // const html = gerarHTMLFinal();
        const html = `
            <div class="tiptap">
                ${replaced}
            </div>
        `;
        // console.log(html);

        formData.append('arquivo', arquivo);
        // formData.append('titulo', titulo);
        formData.append('documento', html);
        // formData.append('assinante', assinante);
        // formData.append('localData', localData);
        formData.append('empresa', empresa);

        //baixar o arquivo da requisição
        try {
            const res = await modeloService.downloadRecibos(formData);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'recibos.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
            toast.error('Erro ao baixar o arquivo!');
        }
    };

    return (
        <Container>
            <ContainerRow>
                <ContainerCollumContent>
                    <BoxForm>
                        <Title style={{marginBottom: '1rem'}}>Document Forge</Title>
                        <SelectsDiv>
                            <FooterItem style={{alignSelf: 'end'}}>
                                <Button onClick={() => navigate('/notas')}>
                                    <Invoice />
                                    Notas
                                </Button>
                                <Button onClick={() => navigate('/crachas')}>
                                    <IdentificationCard />
                                    Crachás
                                </Button>
                                <Button onClick={() => navigate('/registro')}>
                                    <Files />
                                    Registros de Envio
                                </Button>
                                <Button onClick={logout}>
                                    <SignOut />
                                    Logout
                                </Button>
                            </FooterItem>
                                
                            <SelectsRow>
                                <SelectContent>
                                    <label htmlFor="">Modelo</label>
                                    <Select label="Modelo" value={selectValue} onChange={(e) => handleLoadModeloSelected(e.target.value)}>
                                        <Option value="0">Selecione o modelo</Option>
                                        {modelos?.map((modelo) => {
                                            return (
                                                <Option
                                                key={modelo.id_documento}
                                                value={modelo.id_documento}>
                                                    {modelo.titulo_documento}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </SelectContent>
                                <SelectContent>
                                    <label htmlFor="">Setor</label>
                                    <Select label="Setor" value={setor} onChange={(e) => setSetor(e.target.value)}>
                                        <Option value="0">Selecione o setor</Option>
                                        <Option value="1">Financeiro</Option>
                                        <Option value="2">Recursos Humanos</Option>
                                    </Select>
                                </SelectContent>
                                
                            </SelectsRow>
                        </SelectsDiv>
                        
                        <BoxContent>
                            <P>Dados referentes ao PDF</P>
                            {/* <TextArea value={corpo} label="Corpo do PDF" type="text" placeholder="Ex: Eu <<nome>>, inscrito(a) no CPF nº. <<CPF>>..." onChange={(e) => setCorpo(e.target.value)} /> */}
                            <label htmlFor="">Corpo do PDF</label>
                            <InputNotation defaultEditor={defaultEditor}/>
                            {/* <BoxContentRow>
                                <Input value={assinante} label="Assinante" type="text" placeholder="Ex: Estagiário" onChange={(e) => setAssinante(e.target.value)} />
                                <Input value={localData} label="Local e Data" type="text" placeholder="Ex: Fortaleza/CE, 12 de setembro de 2023" onChange={(e) => setLocalData(e.target.value)} />
                            </BoxContentRow> */}
                            <Input
                                label="Arquivo"
                                type="file"
                                onChange={(e) => setArquivo(e.target.files[0])}
                                accept=".xls,.xlsx,.ods"
                            />
                        </BoxContent>
                        <BoxContent>
                            <P>Dados referentes ao Email</P>
                            <Input value={assunto} label="Assunto" type="text" placeholder="Ex: Recibo de Vale Refeição" onChange={(e) => setAssunto(e.target.value)} />
                            <TextArea value={corpoEmail} label="Corpo do Email" type="text" placeholder="Ex: Por favor <<nome>>, devolver assinado em até 3 dias..." onChange={(e) => setCorpoEmail(e.target.value)} />
                            <SelectsDiv>
                                <SelectsRow>
                                    <SelectContent>
                                        <label htmlFor="">Email para cópia</label>
                                        <Select value={emailCopia} onChange={(e) => setEmailCopia(e.target.value)}>
                                            <Option value="0">Não enviar cópia</Option>
                                            {emails?.map((email) => {
                                                return (
                                                    <Option
                                                    key={email}
                                                    value={email}>
                                                        {email}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </SelectContent>
                                    <SelectContent>
                                        <label htmlFor="">Empresa</label>
                                        <Select value={empresa} onChange={(e) => setEmpresa(e.target.value)}>
                                            <Option value="0">Selecione a empresa</Option>
                                            <Option value="1">JIT Technology</Option>
                                            <Option value="2">Center 1</Option>
                                            <Option value="3">Maré Praia Hotel</Option>
                                            <Option value="4">OK Intercâmbio</Option>
                                            <Option value="5">OS Solution</Option>
                                        </Select>
                                    </SelectContent>
                                </SelectsRow>
                            </SelectsDiv>
                            
                        </BoxContent>
                        <BoxFooter>
                            <FooterItem>
                                {modelIdSelected && <Button onClick={handleTabEditar} $bg='#677c9b'><CheckSquare/>Salvar Alterações</Button>}
                                {modelIdSelected && <Button onClick={handleTabDeletar} $bg='#ef4444'><Trash/>Exluir Modelo</Button>}
                                {!modelIdSelected && <Button onClick={handleTabAdicionar} $bg='#677c9b'><FilePlus/>Adicionar modelo</Button>}
                            </FooterItem>
                        </BoxFooter>
                        <BoxFooter>
                            <FooterItem>
                                <Button onClick={handleDownloadRecibos} >
                                    <FileArchive />
                                    Baixar Recibos
                                </Button>
                                <Button onClick={handleDownloadAll}>
                                    <BoxArrowDown />
                                    Baixar todos
                                </Button>
                                <Button onClick={handleDownload} >
                                    <FileArrowDown />
                                    Baixar Exemplo
                                </Button>
                                <Button onClick={handleSubmit}>
                                    <PaperPlaneRight />
                                    Disparar Emails
                                </Button>
                            </FooterItem>
                        </BoxFooter>
                    </BoxForm>
                </ContainerCollumContent>
            </ContainerRow>
            <Toaster position="top-right" richColors duration={1000 * 2.5}/>
            <DialogRoot open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogPortal>
                    <DialogOverlay>
                        <DialogContent>
                            <DialogTitle />
                            <DialogDescription />
                            <Tabs.Root value={tabSelected}>
                                <Tabs.List></Tabs.List>
                                <Tabs.Content value='tab-adicionar'>
                                    <Input value={nomeModeloToSend} onChange={e => setNomeModeloToSend(e.target.value)}
                                    label={'Título do Modelo'} type='text' placeholder={'Ex: Recibo de Vale Refeição'} />
                                    <BoxFooter style={{marginTop: '1rem'}}>
                                        <FooterItem>
                                            <Button onClick={closeDialog} $bg='#ef4444'>
                                                Cancelar
                                            </Button>
                                            {!modelIdSelected && <Button onClick={handleAddModel}>Salvar Modelo</Button>}
                                            {modelIdSelected && <Button onClick={handleEditModel}>Alterar Modelo</Button>}
                                        </FooterItem>
                                    </BoxFooter>
                                </Tabs.Content>
                                <Tabs.Content value='tab-deletar'>
                                    <h1>Realmente deseja excluir esse modelo?</h1>
                                    <p style={{fontWeight: 'bold'}}>{nomeModeloToSend}</p>
                                    <BoxFooter style={{marginTop: '1rem'}}>
                                        <FooterItem>
                                            <Button onClick={closeDialog} $bg='#ef4444'>
                                                Cancelar
                                            </Button>
                                            <Button onClick={handleDeleteModel}>
                                                Excluir Modelo
                                            </Button>
                                        </FooterItem>
                                    </BoxFooter>
                                </Tabs.Content>
                            </Tabs.Root>
                            
                        </DialogContent>
                    </DialogOverlay>
                </DialogPortal>
            </DialogRoot>
        </Container>
    );
};

export default Home;
